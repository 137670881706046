export class BuildProfile {
    rid: any
    id: any
    cpu: any
    cinebench_score: any
    source: string
    sourceVersion: string
    benchmarks = []
    gpu: IGPU[] = []
    memory: IMemory[] = []
    images = []
    title: any
    uid: any
    isPrivate: boolean;
    mem_total: any
    cpu_id: any
    model: any;
    freq: any;
    user_id: any;
    user: any;
    compareBarHidden: boolean = false;

    constructor(benchmarkData) {
        this.rid = benchmarkData.id;
        this.id = benchmarkData.id;
        this.cpu = benchmarkData.cpu;
        this.model = benchmarkData.cpu.model;
        this.freq = benchmarkData.freq;
        this.title = benchmarkData.title;
        this.cpu_id = benchmarkData.cpu.cpu_id;
        this.user_id = benchmarkData.user_id;
        this.user = benchmarkData.user;
        this.source = benchmarkData.source;
        this.sourceVersion = benchmarkData.source_version;
        this.images =[]
        for (let i = 0; i < benchmarkData.images.length; i++) {
            this.images.push({
                src: benchmarkData.images[i].url,
                path: benchmarkData.images[i].url,
                caption: '',
                thumb: benchmarkData.images[i].url
            })
        }
        this.mem_total = 0;
        this.isPrivate = benchmarkData.private;
        for(let i=0;i<benchmarkData.benchmarks.length;i++){
            let score_total = 0;
            let scores = []
            for(let r=0;r<benchmarkData.benchmarks[i].runs.length;r++) {
                score_total += benchmarkData.benchmarks[i].runs[r].score
                scores.push(benchmarkData.benchmarks[i].runs[r].score)
            }
            benchmarkData.benchmarks[i].averageScore = score_total / scores.length
        }
        this.benchmarks = benchmarkData.benchmarks
        try {
            for (let g = 0; g < benchmarkData.gpu.length; g++) {
                this.gpu.push(benchmarkData.gpu[g])
            }
        } catch (e) {

        }
        for (let r = 0; r < benchmarkData.memory.length; r++) {
            this.memory.push(benchmarkData.memory[r])
            this.mem_total += Number(benchmarkData.memory[r].gigabytes)
        }
        this.freq = benchmarkData.freq;
    }

}

export interface IGPU {
    make: any
    model: any
}

export interface IMemory {
    make: any
    model: any
    modelname: any
    speed: any
    gigabytes:any
}
