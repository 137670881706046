import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ILoginResponse, IResponse, User} from "./interfaces/response";
import {HttpClient} from "@angular/common/http";
import {WINDOW} from "./window";

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(private http:HttpClient, @Inject(WINDOW) private window: Window) {}

    _loginUrl: string = UserService.getHostname() + "/login";
    _signUpUrl: string = UserService.getHostname() + "/signup";
    _resultsUrl: string = UserService.getHostname() + "/account/results";
    _profileUrl: string = UserService.getHostname() + "/account/profile";
    _editProfile: string = UserService.getHostname() + "/account/edit";
    _compareUrl: string = UserService.getHostname() + "/comparisons/get_comparison";
    _authUrl: string = UserService.getHostname() + "/authenticate";
    _logoutUrl: string = UserService.getHostname() + "/logout";
    _captureEmailUrl: string = UserService.getHostname() + "/email/capture_email";
    public loggedIn = new Subject<boolean>();
    public user: User|any;

    login(email: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._loginUrl, {email: email, password: password}, {})
    }
    editName(uid: any, newName: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._editProfile, {uid: uid, newName: newName}, {})
    }
    captureEmail(email: string): Observable<any> {
        return this.http.post<any>(this._captureEmailUrl, {email: email}, {})
    }
    signup(email: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._signUpUrl, {email: email, password: password}, {})
    }
    results(): Observable<any> {
        return this.http.get<any>(this._resultsUrl, {observe: 'response'})
    }
    getProfile(uid?: any): Observable<IResponse> {
        return this.http.post<IResponse>(this._profileUrl, {uid: uid}, {})
    }
    getComparison(compareId: any): Observable<IResponse> {
        return this.http.post<IResponse>(this._compareUrl, {compare_id: compareId}, {})
    }
    getAuth(): Observable<any> {
        return this.http.post<boolean>(this._authUrl, {}, {})
    }

    logout(): Observable<boolean> {
        return this.http.post<boolean>(this._logoutUrl, {}, {})
    }

    isLoggedIn(): Observable<boolean>{
        return this.loggedIn
    }

    static getHostname() {
        if (window.location.hostname == 'localhost') {
			return 'http://localhost:8180'
		} else {
			return 'https://api.comparebench.com'
		}
    }
}
