    <div class="container-fluid">
        <div class="row">
            <div class="card col-md-12">
                <div class="card-body">
                    <div class="row white">
                            <br>
                            <div class="col-md-12">
                                <h1 class="centered" style="text-align: center;    font-family: 'Raleway';font-weight: 300; color: #333;"><img src="https://r2.comparebench.com/assets/logo_footer.png"></h1>
                                <h4 style="text-align: center;    font-family: 'Raleway';font-weight: 300;">Roadmap</h4>
                            </div>
                            <hr class="hr-landing">
                            <div style="width: 80%;margin: 0 auto;">
                                <p>We've put together a very basic roadmap so you can see what we're working on and where our priorities are for each "milestone".
                                    We don't have an ETA for these items, but we'll keep this and our <a href="https://twitter.com/comparebench">twitter</a> updated as we build our platform. You can also follow along on our <a href="https://discord.gg/V3nRgYA">Discord</a>!</p>
                            </div>
                        </div>
                        <div class="roadmap-background">
                            <div class="roadmap-container">
                                <div class="roadmap-panel roadmap-table">
                                    <div class="roadmap-table-inner">
                                        <img src="/assets/paper.png" alt="" class="roadmap-img">
                                        <h2 class="milestone-header">Milestone 1</h2>
                                        <p style="text-transform: none">Early items that need some extra polish </p>
                                        <ul class="roadmap-features">
                                            <li class="roadmap-features-item">"Quality of life", UX improvements, core bug fixes</li>
                                            <li class="roadmap-features-item">Support for additional benchmark programs</li>
                                            <li class="roadmap-features-item">Launch <a routerLink="/yardstick">Yardstick Beta 1</a></li>
                                        </ul>
                                    </div>

                                    <div class="roadmap-table-inner">
                                        <img src="/assets/airplane.png" alt="" class="roadmap-img">
                                        <h2 class="milestone-header">Milestone 2</h2>
                                        <p style="text-transform: none">New or expanded features for the project</p>
                                        <ul class="roadmap-features">
                                            <li class="roadmap-features-item">Launch Revisions (details tbd)</li>
                                            <li class="roadmap-features-item">Launch <a routerLink="/yardstick">Yardstick Beta 2</a></li>
                                            <li class="roadmap-features-item">Social features like comments, user posts, etc</li>
                                        </ul>
                                    </div>

                                    <div class="roadmap-table-inner">
                                        <img src="/assets/shuttle.png" alt="" class="roadmap-img">
                                        <h2 class="milestone-header">Milestone 3</h2>
                                        <p style="text-transform: none">Final core feature-sets</p>
                                        <ul class="roadmap-features">
                                            <li class="roadmap-features-item">Influencer features like Creator pages and more</li>
                                            <li class="roadmap-features-item">Feature parity between Yardstick and Web</li>
                                            <li class="roadmap-features-item">Launch <a routerLink="/yardstick">Yardstick 1.0</a></li>
                                            <li class="roadmap-features-item">Public Discord bot to fetch product details and price tracking</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
