import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../user.service";
import {Router} from "@angular/router";
import {WINDOW} from "../../window";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {User} from "../../interfaces/response";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
    })

    constructor(@Inject(WINDOW) private window: Window, private formBuilder: UntypedFormBuilder, private userService: UserService, private router: Router) {

    }

    login() {
        this.userService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe((response) => {
            if (response.status === true) {
                this.userService.user = new User(response.user);
                this.userService.loggedIn.next(true);
                this.router.navigate(['/dashboard'])
            }
        })
    }

    discord() {
        this.window.location.href = "https://discord.com/api/oauth2/authorize?client_id=854808456828551189&redirect_uri=https%3A%2F%2Fapi.comparebench.com%2Foauth%2Fdiscord&response_type=code&scope=identify%20email"
    }

    ngOnInit() {
    }

}
